import React from "react";
import Bio from "../../components/Bio";

const staff=[];

const OurStaff=()=>{
    return (
        <div>
            <Bio bods={staff}/>
        </div>
    );
}
export default OurStaff;